"use client"

import { usePathname } from "next/navigation"
import { useEffect } from "react"

export default function FeedbackLoopProvider() {
  const pathname = usePathname()
  useEffect(() => {
    const query = Math.floor(Math.random() * 900) + 100

    const script = document.createElement("script")
    script.type = "text/javascript"
    script.src = `https://feedbackloopwidget.vercel.app/widget.js?q=${query}`
    script.async = true
    script.id = "feedbackLoopWidget"
    script.className = "clxapi8gc0001jt096ubtm05i"
    const style = document.createElement("link")
    style.href = "https://feedbackloopwidget.vercel.app/widgetStyle.css"
    style.rel = "stylesheet"
    document.head.appendChild(style)
    document.head.appendChild(script)

    return () => {
      if (script) {
        document.head.removeChild(script)
      }
    }
  }, [pathname])
  return <></>
}
