"use client"

import { TooltipProvider } from "@radix-ui/react-tooltip"
import type { ThemeProviderProps } from "next-themes/dist/types"

export function TooltipProviderParent({
  children,
  ...props
}: ThemeProviderProps): JSX.Element {
  return <TooltipProvider {...props}>{children}</TooltipProvider>
}
