import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

import { env } from "@/env.mjs"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function getStartOfWeek() {
  const now = new Date()
  const day = now.getDay()
  const diff = now.getDate() - day + (day === 0 ? -6 : 1) // Adjust if the current day is Sunday
  return new Date(now.setDate(diff))
}

export function scrollToTop(): void {
  // Check if the window.scrollTo function is available
  if (window.scrollTo) {
    // Use smooth behavior for scrolling
    window.scrollTo({
      top: 0,
      behavior: "auto", // Use 'auto' for instant scrolling
    })
  } else {
    // Fallback for older browsers
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
  }
}

export function checkAndSplitString(input: string): string[] {
  if (input.includes(",")) {
    return input.split(",").map((str) => str.trim())
  } else {
    return [input]
  }
}

export function formatPrice(price: number | undefined): string {
  if (!price) return "0"
  return price.toLocaleString("fr-FR").replace(/\s/g, ".")
}

export function splitArrayIntoChunks<T>(
  array: T[],
  chunkSize: number = 50
): T[][] {
  const chunks: T[][] = []
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize))
  }
  return chunks
}

export function calculateAverageRating(ratings: number[]): number {
  // Vérifier que le tableau n'est pas vide
  if (ratings.length === 0) {
    throw new Error("Le tableau de notes ne doit pas être vide.")
  }

  // Vérifier que toutes les notes sont comprises entre 1 et 5
  for (const rating of ratings) {
    if (rating < 1 || rating > 5) {
      throw new Error("Toutes les notes doivent être des entiers entre 1 et 5.")
    }
  }

  // Calculer la somme des notes
  const sum = ratings.reduce((acc, rating) => acc + rating, 0)

  // Calculer la moyenne
  const average = sum / ratings.length

  // Arrondir la moyenne à l'entier le plus proche
  const roundedAverage = Math.round(average)

  // S'assurer que le résultat est compris entre 1 et 5
  return Math.max(1, Math.min(5, roundedAverage))
}

export function formatDate(date: Date | string | number) {
  return new Intl.DateTimeFormat("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  }).format(new Date(date))
}

export function absoluteUrl(path: string) {
  return `${env.NEXT_PUBLIC_APP_URL}${path}`
}

export function truncate(str: string, length: number) {
  return str.length > length ? `${str.substring(0, length)}...` : str
}

export async function getGitHubStars(): Promise<number | null> {
  try {
    const response = await fetch(
      "https://api.github.com/repos/pjborowiecki/SAASY-LAND-Next-14-Starters-With-Authentication-And-Database-Implemented",
      {
        headers: {
          Accept: "application/vnd.github+json",
        },
        next: {
          revalidate: 60,
        },
      }
    )

    if (!response.ok) return null

    const data = (await response.json()) as { stargazers_count: number }

    return data.stargazers_count
  } catch (err) {
    console.error(err)
    return null
  }
}
